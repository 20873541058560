/* ==== Pricing CSS ==== */

.pricing {
  background: #fff;
  padding: 50px 0;

  &__title {
    h1 {
      font-weight: 500;
      font-size: 24px;
      color: #4c4c4c;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      color: #000000;
    }
  }

  &__plan {
    padding-top: 24px;

    ul {
      display: inline-flex;
      align-items: center;
      list-style: none;
      margin: 0;
      margin-bottom: 24px;
      padding: 0;
      background: #f2f2f2;
      border-radius: 48px;

      li {
        button {
          border: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #5468ff;
          padding: 8px 22px;
          border-radius: 48px;

          &.active {
            background: #5468ff;
            color: #fff;
          }
        }
      }
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #4c4c4c;
    }
  }

  &__table {
    .table {
      thead {
        tr {
          th {
            text-align: center;
            border: none;
            padding: 8px 20px 32px;
            min-width: 200px;

            h5 {
              font-weight: normal;
              font-size: 20px;
              line-height: 28px;
              color: #000000;
            }

            h2 {
              margin-bottom: 20px;

              span.price {
                font-weight: 500;
                font-size: 36px;
                color: #000000;
              }

              span {
                font-weight: 300;
                font-size: 12px;
                color: #4c4c4c;
              }
            }

            .main-btn {
              width: 100%;

              &.choose-btn {
                background: transparent;
                color: #5468ff;

                &:hover {
                  background: rgba(84, 104, 255, 0.15);
                }
              }

              &.border-btn {
                background: transparent;
                color: #5468ff;
                border-color: #5468ff;

                &:hover {
                  background: rgba(84, 104, 255, 0.15);
                }
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            text-align: center;
            border: none;
            border-right: 1px solid #c4c4c4;
            vertical-align: middle;
            padding-top: 12px;
            padding-bottom: 12px;

            &:first-child {
              text-align: left;
            }

            &:last-child {
              border-right: none;
            }

            p {
              font-weight: normal;
              font-size: 16px;
              color: #000000;

              i {
                font-weight: 700;

                &.lni-checkmark {
                  color: #00ea19;
                }
                &.lni-close {
                  color: #ff4b22;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
