body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
  background: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 0px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-btn {
  padding: 0px 16px;
  height: 40px;
  background: #5468ff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  border: 2px solid transparent;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  line-height: 38px;

  &:hover {
    background: #364df7;
    color: #ffffff;
  }

  &.disable-btn {
    background: #c8caff;
    pointer-events: none;
  }
}

.icon-btn {
  background: #eaeaff;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5468ff;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    background: #5468ff;
    color: #ffffff;
  }

  i {
    font-weight: 700;
  }
}
