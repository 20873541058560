/* ===== AddSnippet css ===== */
.AddSnippet-wrapper {
  form {
    background: #fff;
    padding: 48px 32px;
    border-radius: 12px;

    h3 {
      font-size: 28px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 16px;
    }

    .block-type {
      margin-bottom: 32px;
    }

    .check-type {
      display: flex;

      .form-input {
        position: relative;
        min-width: 120px;
        height: 40px;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.3);

        &:first-child {
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }

        input {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 99;
        }

        input:checked ~ label {
          background: rgba(0, 0, 0, 0.1);
        }

        label {
          position: absolute;
          z-index: 9;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 500;
          color: #000;
        }
      }
    }

    .block-code {
      margin-bottom: 16px;

      .code-input {
        margin-bottom: 16px;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        height: 40px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        margin-bottom: 4px;
      }

      textarea {
        width: 100%;
        height: 320px;
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 4px;
      }
    }

    .preview-image {
      .file-upload {
        position: relative;
        max-width: 350px;
        width: 100%;
        height: 200px;
        border: 1px dashed rgba(0, 0, 0, 0.3);
        margin-bottom: 32px;
        border-radius: 4px;

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          appearance: none;
          z-index: 9;
        }

        label {
          z-index: 1;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          i {
            font-size: 24px;
            color: #7d7d7d;
            font-weight: 700;
            margin-bottom: 16px;
          }
          span {
            font-size: 18px;
            color: #7d7d7d;
          }
        }
      }
    }
  }
}
