/* ==== AccountSettings CSS ==== */

.accessList {
  &--wrapper {
    padding-top: 30px;
  }

  .title {
    h2 {
      font-size: 36px;
      line-height: 45px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 16px;
    }
  }

  &__table {
    .table {
      thead {
        tr {
          position: relative;
          border-bottom: 2px solid transparent;

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: white;
            border-radius: 8px;
            z-index: -1;
          }

          th {
            border: none;
            vertical-align: middle;

            span {
              font-size: 14px;
              color: #4c4c4c;
              font-weight: 400;
              padding: 8px;
              display: block;

              &.w-140 {
                min-width: 140px;
              }

              &.w-160 {
                min-width: 160px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          position: relative;
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: white;
            border-radius: 8px;
            z-index: -1;
          }

          td {
            border: none;
            vertical-align: middle;

            p {
              font-size: 16px;
              font-weight: 400;
              color: #000000;
              margin-bottom: 0px;
              padding: 8px;
              display: flex;
            }
          }
        }
      }
    }
  }
}
