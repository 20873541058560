.login-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-content {
    max-width: 550px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.32);
    padding: 65px;
    background: #fff;

    h1 {
      font-size: 24px;
      font-weight: 500;
      margin: 0px;
      margin-bottom: 8px;
      color: #000;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      color: #000;
      margin-bottom: 32px;
    }

    p.toggleUser {
      font-size: 16px;
      font-weight: 400;

      span {
        color: #5468ff;
        padding-left: 10px;
        cursor: pointer;
        font-weight: 500;
      }
    }

    .single-input {
      margin-bottom: 16px;

      label {
        font-size: 12px;
        font-weight: 300;
        color: #4c4c4c;
        display: block;
        padding-left: 10px;
        margin-bottom: 8px;
      }

      .input-field {
        position: relative;

        input {
          width: 100%;
          background: #efefef;
          height: 40px;
          border-radius: 4px;
          border: 1px solid transparent;
          padding: 0px 25px 0px 44px;
          color: #000;
          font-size: 16px;
          font-weight: 400;

          &::placeholder {
            color: inherit;
          }

          &:focus,
          &:active {
            border-color: #5468ff;
            outline: none;
          }
        }

        span.icon {
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          height: 40px;
          border-radius: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(0, 0, 0, 0.64);
          font-size: 20px;

          i {
            font-weight: 700;
          }
        }
      }

      .forget-password {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        color: #5468ff;
        padding: 0;
        padding-top: 4px;
        background: transparent;
        border: none;
      }

      .main-btn {
        margin-top: 4px;
      }
    }
    .forget-message {
      color: rgb(23, 131, 255);
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 0px;
    }
  }
}
